Beacon.DelayedFunction = function (callback, scope, timeout) {

    var self = this;
    self.timerId = null;
    self.callback = callback;
    self.timeout = timeout;
    self.scope = scope;

    self.destroy = function () {
        if (self.timerId) {
            clearTimeout(self.timerId);
            self.timerId = null;
        }
        self.callback = null;
        self.scope = null;
    };

    self.delayedInvoke = function () {

        //if the timer is already going, reset it
        if (self.timerId) {
            clearTimeout(self.timerId);
            self.timerId = null;
        }

        //set it and start timing
        var invcb = self.invoke;
        var me = self;

        self.timerId = setTimeout(function () {
            invcb.call(me);
        }, self.timeout);

    };

    // called by the timer 
    self.invoke = function () {
        self.callback.call(self.scope);
        self.timerId = null;
    };

    return self;
}