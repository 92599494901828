
// this class is exclusively used by Beacon.Layer.SelectionLayer

Beacon.ResultsPane = {

    resultsPaneDiv: null,
    tabControl: null,

    //
    // hasResults : there are items in the result set
    // userVisibility: user wants to see the list when not empty
    //


    initialize: function () {
        this.resultsPaneDiv = $('#ResultsPaneDiv');
        //this.resultsPaneDiv.empty();

        this.resultsPaneDiv.find("*").not(".results-header").not("#btnCloseResultsPane").remove()

        this.isVisible = this.resultsPaneDiv.is(':visible');

        this.tabControl = $('#map-pane-tab-results');
        this.tabControl.click(this.onResultsTabClick);

        $("#btnCloseResultsPane").click(this.onCloseClick);

    },

    isVisible: false,
    isWanted: true,

    regx: /[^a-zA-Z0-9\-_]/g, // Only allow A-Z 0-9 - _

    getsafeid: function (featureId) {
        return 'resId_' + featureId.replace(this.regx, '·');
    },

    onResultsTabClick: function (e) {
        Beacon.ResultsPane.isWanted = true;
        //$('.map-wrap').removeClass('hide-map-pane-results');
        Beacon.ResultsPane._removeHideClass();

        e.preventDefault();
    },

    onCloseClick: function (e) {
        Beacon.ResultsPane.isWanted = false;
        //$('.map-wrap').addClass('hide-map-pane-results');
        Beacon.ResultsPane._addHideClass();

        e && e.preventDefault();
    },

    // PUBLIC API
    addToSelection: function (featureId, parentId, featureHtml, hoverCallback, clickCallback, callbackScope) {

        //we are adding features, so we should show the pane if its hidden
        if (!this.isVisible) {
            this.showResultsPane();
            this.isVisible = true;
        }

        var id = this.getsafeid(featureId);

        var html = '<div id="' + id + '" class="resultitem">' + featureHtml;
        if (parentId) {
            html += '<div style="font-size:7pt;">[multi-parcel feature]</div>';
        }
        if (html.indexOf('No Data Found') === -1) {
            html += '<div style="font-size:7pt;">' + Beacon.Tabs.getReportLinksHtml(featureId) + '</div>';
        }
        
        html += '</div>';

        this.resultsPaneDiv.append(html);

        var fdiv = $('#' + id, this.resultsPaneDiv); //grab a reference to the object

        $("A", fdiv).click(function (e) { e.stopPropagation(); }); //make sure the links don't generate setkeyvalue events

        //add hover events
        fdiv.hover(
            function () {
                fdiv.addClass('resultitemhover');

                if (hoverCallback) {
                    hoverCallback.call(callbackScope, featureId, true);
                }
                //if (Beacon.MapSV.map) {
                //    Beacon.MapSV.map.OnResultPaneFeatureHover(featureId, true);
                //}

            },
            function () {

                fdiv.removeClass('resultitemhover');

                if (hoverCallback) {
                    hoverCallback.call(callbackScope, featureId, false);
                }
                //if (Beacon.MapSV.map) {
                //    Beacon.MapSV.map.OnResultPaneFeatureHover(featureId, false);
                //}
            });



        fdiv.click(function () {

            if (clickCallback) {
                clickCallback.call(callbackScope, featureId);
            }
            //if (Beacon.MapSV.map) {
            //    Beacon.MapSV.map.OnResultPaneFeatureClick(featureId);
            //}

        });

    },

    // PUBLIC API
    removeFromSelection: function (featureId) {

        var id = this.getsafeid(featureId);
        var fdiv = $('#' + id, this.resultsPaneDiv);

        if (fdiv) {
            fdiv.remove();
        }

        this.updateDisplay(0, null);

    },

    // PUBLIC API
    setKeyFeatureId: function (featureId) {

        //clear the old css
        $('.resultitemkey', this.resultsPaneDiv).removeClass('resultitemkey');

        var id = this.getsafeid(featureId);
        var fdiv = $('#' + id, this.resultsPaneDiv);

        var firstDiv; //= $('DIV:first', this.resultsPaneDiv);

        if ($("#resultsWarningPane").length == 0) {
            firstDiv = $('DIV:first', this.resultsPaneDiv);
        } else {
            firstDiv = $('DIV:not(#resultsWarningPane):first', this.resultsPaneDiv);
        }

        if (fdiv[0].id == firstDiv[0].id) return;

        if (fdiv) {
            var rp = this.resultsPaneDiv;
            fdiv.insertBefore(firstDiv);
            $(this.resultsPaneDiv).scrollTo('0%', 200);
        }

        fdiv.addClass('resultitemkey');

    },

    // PUBLIC API
    updateDisplay: function (featureCount) {
        if (featureCount > 0) {
            if (!this.isVisible) {
                this.showResultsPane();
                this.isVisible = true;
            }
            this.tabControl.removeClass("no-results-available");
            //Beacon.Tabs.onSelectionChanged(true, keyValue);
        } else {
            if (this.isVisible) {
                this.hideResultsPane();
                this.isVisible = false;
                this.tabControl.addClass("no-results-available");
                //Beacon.Tabs.onSelectionChanged(false, keyValue);
            }
        }

        // ui hints for old browser limits
        if (Beacon.BrowserCompatibility.limitResults) {

            if (featureCount >= Beacon.BrowserCompatibility.limitResults) {

                if ($("#resultsWarningPane").length == 0) {

                    var html = "<div id='resultsWarningPane'>"
                                + "<span style='color:Red; font-weight:bold'>Warning!</span> "
                                + "&nbsp;Due to performance issues, your browser is limited to selecting a maximum of "
                                + Beacon.BrowserCompatibility.limitResults + " items on the map. "
                                + "Upgrade to a modern browser to remove this limit. "
                                + "<a href='https://browser-update.org/update.html'>Learn more</a>"
                                + "</div>";

                    this.resultsPaneDiv.prepend(html);
                }

            } else {
                $("#resultsWarningPane").remove();
            }

        }
    },

    // PUBLIC API
    onFeatureMouseOver: function (featureid) {
        var id = this.getsafeid(featureid);
        var fdiv = $('#' + id, this.resultsPaneDiv);
        fdiv.addClass('resultitemhover');
    },

    // PUBLIC API
    onFeatureMouseOut: function (featureid) {
        var id = this.getsafeid(featureid);
        var fdiv = $('#' + id, this.resultsPaneDiv);
        fdiv.removeClass('resultitemhover');
    },

    // if set, we are counting down to hiding the results pane.  If the showResultsPane is called, we clear this and cancel the timer.
    _hideTimer: null,

    showResultsPane: function () {

        if (this._hideTimer) {
            clearTimeout(this._hideTimer);
            this._hideTimer = null;
        }

        if (!Beacon.MapJS.mobileDevice) {
            if (this.isWanted) {
                //$('.map-wrap').removeClass('hide-map-pane-results');
                Beacon.ResultsPane._removeHideClass();
            }
        }
        else {
            //$('.map-wrap').addClass('hide-map-pane-results');
            Beacon.ResultsPane._addHideClass();
        }

    },

    hideResultsPane: function () {
        if (this._hideTimer) {
            clearTimeout(this._hideTimer);
            this._hideTimer = null;
        }

        this._hideTimer = setTimeout(function () {
            //$('.map-wrap').addClass('hide-map-pane-results');
            Beacon.ResultsPane._addHideClass();
        }, 1000);

    },

    _addHideClass: function() {
        $('.map-wrap').addClass('hide-map-pane-results');
        this.isVisible = false;

        $(".bEsriLogo").css("right", "0");
        $(".bControlDisplayXY").css("right", "0");
        $("coordinates").css("right", "0");
        $(".esri-ui-top-right").css("right", "0");
        $(".esri-ui-bottom-right").css("right", "0");


        this._shoreUpDetails();


    },

    _removeHideClass: function() {
        $('.map-wrap').removeClass('hide-map-pane-results');
        this.isVisible = true;

        var w = Beacon.ResultsPane.resultsPaneDiv.width() + 8;
        $(".bEsriLogo").css("right", w);
        $(".bControlDisplayXY").css("right", w);
        $("coordinates").css("right", w);
        $(".esri-ui-top-right").css("right", w);
        $(".esri-ui-bottom-right").css("right", w);

        this._shoreUpDetails();
    },

    _shoreUpDetails: function () {
        const el = $("#mapJsDetailDiv");
        let h = 0;
        if (el[0] && el[0].style.display !== "none") {
            h = el.height();
        }
        $(".esri-ui-bottom-right").css("margin-bottom", h);
        $(".esri-ui-bottom-left").css("margin-bottom", h);
    },

    CLASS_NAME: 'Beacon.ResultsPane'
};
