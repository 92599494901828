
Beacon.QuickZoomService = {

    // Requires: mapConfig.Layers
    // Uses: Beacon.API.GetQuickZoomList

    _cache: {}, // key is layerid, value is data returned from svc, or [] on failure

    isQuickZoomable: function (layerId) {
        if (!Beacon.MapJS.getBeaconLayer)
            return false; // This get's hit prematurely sometimes in initializaion.

        var lyr = Beacon.MapJS.getBeaconLayer(layerId);
        return lyr && lyr.QuickZoom;
    },

    /// Returns a jQuery promise - args: layerId, data
    ///    If no data is available, or on communications failure, data = []
    requestQuickZoomData: function (layerId) {
        var that = this;
        
        var dfd = new jQuery.Deferred();

        var zoomable = this.isQuickZoomable(layerId);

        if (zoomable) {

            var cachedData = Beacon.QuickZoomService._cache[layerId];

            if (cachedData) {
                dataReadyForUse(cachedData);
            }
            else {

                Beacon.API.GetQuickZoomList(layerId,
                      function (d) {
                          try {
                              if (d && d.length > 0) {
                                  dataReadyForUse(d);
                              } else {
                                  dataReadyForUse([]);
                              }
                          } catch (e) {
                              //fail!
                              dataReadyForUse([]);
                          }
                      },
                      function (fail) {
                          //fail!
                          dataReadyForUse([]);
                      },
                      this,
                      layerId);
            }
        } else {
            // no data here either
            dataReadyForUse([]);
        }

        return dfd.promise();

        function dataReadyForUse(data) {
            Beacon.QuickZoomService._cache[layerId] = data;
            dfd.resolve(layerId, data);
        }
    },



    CLASS_NAME: 'Beacon.QuickZoomService'
};
